import { ErrorOutline } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { useProjectName } from "./hooks/projectsHooks";

const useStyles = makeStyles(() => ({
  root: {
    "&:first-letter": {
      textTransform: "uppercase"
    }
  }
}));
interface ProjectNameProps {
  solutionKey: string;
  projectId: string;
}

export default function ProjectName({
  projectId,
  solutionKey
}: ProjectNameProps) {
  const { root } = useStyles({});
  const { data, error } = useProjectName({
    solutionKey,
    projectId
  });

  const project = data?.solutions.solution?.projects?.project;
  const name = data?.solutions.solution?.projects?.project?.name;
  return project === null || error ? (
    <ErrorOutline color="secondary" />
  ) : (
    <div data-testid="project-details-nav" className={root}>
      {name || projectId}
    </div>
  );
}
