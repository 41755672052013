import { ExpandMore } from "@mui/icons-material";
import {
  ClickAwayListener,
  Fade,
  List,
  ListItem,
  ListItemText,
  Popper,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useRef } from "react";
import semver from "semver";
import useQueryUseCaseVersions, {
  Versions
} from "./topics/hooks/useQueryUseCaseVersions";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center"
  },

  versionsList: {
    backgroundColor: "#ebf0f5"
  },
  listItem: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    "&:hover": {
      backgroundColor: "#cdd9e1"
    }
  },
  listItemText: {
    color: "#005578",
    textAlign: "center"
  },
  expandMore: {
    "&:focus": {
      outline: "none"
    }
  },
  popper: {
    maxHeight: "400px !important",
    overflowX: "hidden",
    overflow: "auto",
    left: "-48px !important",
    top: "21px !important",
    zIndex: 1000,
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
  }
}));

function getUseCaseVersions(versions: Versions | undefined) {
  const useCases =
    (versions && versions.solutions.solution?.useCases?.useCases) || [];
  const useCaseVersions = useCases
    .map(({ detail: { version } }) => version)
    .sort(semver.compare);
  return useCaseVersions;
}

type UseCaseVersionSelectionProps = {
  solutionKey: string;
  useCaseKey: string;
  onSelectVersion: (version: string) => void;
};

const UseCaseVersionSelection: React.FC<UseCaseVersionSelectionProps> = ({
  solutionKey,
  useCaseKey,
  onSelectVersion
}) => {
  const { data, loading } = useQueryUseCaseVersions({
    solutionKey,
    useCaseKey
  });
  const { root, expandMore, versionsList, listItem, listItemText, popper } =
    useStyles({});
  const versions = getUseCaseVersions(data).reverse();
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef(null);
  const stopEventPropagation = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const showUseCaseVersions = async (e: React.MouseEvent) => {
    stopEventPropagation(e);
    setOpen(prev => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };
  if (loading || versions.length === 0) return null;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={root}>
        <ExpandMore
          className={expandMore}
          onClick={showUseCaseVersions}
          ref={anchorRef}
          aria-owns="popper"
        />
        <Popper
          id={open ? "popper" : undefined}
          open={open}
          anchorEl={anchorRef.current}
          transition
          className={popper}
        >
          {({ TransitionProps }) => (
            <Fade in={open} timeout={350} {...TransitionProps}>
              <List component="div" className={versionsList}>
                {versions.map(version => (
                  <ListItem
                    className={listItem}
                    key={version}
                    onClick={(e: React.MouseEvent) => {
                      stopEventPropagation(e);
                      onSelectVersion(version);
                      handleClose();
                    }}
                  >
                    <ListItemText
                      className={listItemText}
                      primary={
                        <Typography variant="body1">{version}</Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default UseCaseVersionSelection;
