import { ErrorOutline } from "@mui/icons-material";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import useQueryUseCaseName from "./topics/hooks/useQueryUseCaseName";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&:first-letter": {
      textTransform: "uppercase"
    }
  }
}));
interface UseCaseNameProps {
  solutionKey: string;
  useCaseKey: string;
  useCaseVersion: string;
}

export default function UseCaseNameAndVersion({
  solutionKey,
  useCaseKey,
  useCaseVersion
}: UseCaseNameProps) {
  const { root } = useStyles({});
  const { data, error } = useQueryUseCaseName({
    solutionKey,
    useCaseKey,
    useCaseVersion
  });
  const useCase = data?.solutions.solution?.useCases?.useCases?.[0];
  const name = useCase?.detail?.name;
  return error ? (
    <ErrorOutline color="secondary" />
  ) : (
    <div data-testid="use-case-topic" className={root}>{`${
      name || useCaseKey
    } ${useCaseVersion}`}</div>
  );
}
