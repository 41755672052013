import { ApolloError } from "@apollo/client";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Card from "./Card";
import TraceableErrorDetail from "./TraceableErrorDetail";
import { copyErrors, createTraceableErrors } from "./errorUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    traceableError: {
      padding: theme.spacing(0, 1, 1, 1),
      "&>:nth-child(odd)>div>div": {
        backgroundColor: "rgba(0, 0, 0, 0.04)"
      },
      "&>:nth-child(even)>div>div": {
        backgroundColor: "#ffffff"
      }
    }
  })
);

interface ErrorBoxProps {
  onClose?: (value: boolean) => void;
  apolloError: ApolloError | ApolloError[];
  title?: string;
  closable?: boolean;
  maxHeight?: number;
}
export default function ErrorBox({
  onClose,
  apolloError,
  title = "An error occurred",
  closable = false,
  maxHeight
}: ErrorBoxProps) {
  const classNames = useStyles({});
  const traceableErrors = createTraceableErrors(
    Array.isArray(apolloError) ? apolloError : [apolloError]
  );
  return (
    <Card
      title={title}
      closable={closable}
      onClose={onClose}
      copy={() => {
        copyErrors(traceableErrors);
      }}
    >
      <div className={classNames.traceableError}>
        {traceableErrors.map(({ errorStacks }, index) => (
          <TraceableErrorDetail
            key={index}
            errorStacks={errorStacks}
            maxHeight={maxHeight}
          />
        ))}
      </div>
    </Card>
  );
}
