import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSolutionKey } from "features/solution";
import ResultsIcon from "mdi-material-ui/TableSearch";
import { notNullOrUndefined } from "utils/typescript";
import Topic from "../Topic";
import { useQueryUseCaseJobsCount } from "../results/hooks/AllCalculationsResultsHooks";
import DataAndCalculationsCountError from "./DataAndCalculationsCountError";

const useStyles = makeStyles(theme => ({
  right: {
    flex: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    textTransform: "capitalize",
    padding: theme.spacing(1),
    flexDirection: "column"
  },
  text: {
    textAlign: "left",
    color: "#fff",
    width: "100%",
    textTransform: "capitalize",
    fontWeight: "bold"
  },
  topicColor: {
    backgroundColor: theme.palette.primary.dark,
    marginRight: theme.spacing(4)
  },
  divider: {
    height: 32,
    borderLeft: "solid 2px #ffffff"
  },
  calculationNumber: { fontSize: 60 },
  badge: { fontSize: 12, marginBottom: -10 },
  flex: { display: "flex", alignItems: "center" },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    marginRight: theme.spacing(3)
  },
  allCalculations: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(1)
  },
  icon: { marginRight: 16, fontSize: 60 }
}));

type CalculationsCardProps = {
  useCaseKey: string;
  useCaseVersion?: string;
};

export default function CalculationsCard({
  useCaseKey,
  useCaseVersion
}: CalculationsCardProps) {
  const solutionKey = useSolutionKey();
  const jss = useStyles();
  const {
    data: allCalculations,
    loading: loadingAllCalculations,
    error: allCalculationsCountError
  } = useQueryUseCaseJobsCount({
    solutionKey,
    useCaseKey,
    useCaseVersion,
    scheduler: null
  });
  const {
    data: myCalculations,
    loading: loadingMyCalculations,
    error: myCalculationsCountError
  } = useQueryUseCaseJobsCount({
    solutionKey,
    useCaseKey,
    useCaseVersion,
    scheduler: "me"
  });
  const errors = [allCalculationsCountError, myCalculationsCountError].filter(
    notNullOrUndefined
  );

  return (
    <Topic
      title="Calculations"
      icon={<ResultsIcon fontSize="large" className={jss.icon} />}
      dataTest="topic-show-calculations"
      isLoading={loadingMyCalculations || loadingAllCalculations}
      to="./calculations"
      colorClassName={jss.topicColor}
      disabledMessage={
        allCalculations?.solutions.solution?.useCases?.useCase
          ?.calculationsCount !== 0 ? undefined : (
          <span>Schedule a calculation to see results</span>
        )
      }
    >
      {myCalculations && allCalculations ? (
        <div className={jss.flex}>
          <div className={jss.flexColumn}>
            <Typography className={jss.badge}>Mine</Typography>
            <Typography className={jss.calculationNumber}>
              {
                myCalculations.solutions.solution?.useCases?.useCase
                  ?.calculationsCount
              }
            </Typography>
          </div>
          <div className={jss.divider}></div>
          <div className={jss.allCalculations}>
            <Typography className={jss.badge}>All</Typography>
            <Typography className={jss.calculationNumber}>
              {
                allCalculations.solutions.solution?.useCases?.useCase
                  ?.calculationsCount
              }
            </Typography>
          </div>
        </div>
      ) : null}
      {errors.length > 0 && (
        <DataAndCalculationsCountError
          error={errors}
          tooltipTitle="An error occurred while loading the calculations, click this icon to see it."
          errorBoxTitle="An error occurred while loading the calculations count!"
        />
      )}
    </Topic>
  );
}
