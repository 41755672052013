import makeStyles from "@mui/styles/makeStyles";
import { RenderableColumnDefinition } from "components/grid/gridTypings";
import Header from "components/tableSetExplorer/Header";
import TableSetGrid from "components/tableSetExplorer/TableSetGrid";
import { IColumnProps } from "devextreme-react/data-grid";
import {
  QueryCalculationResultInputDataVariables,
  QueryCalculationResultInputData_solutions_solution_useCases_useCases_calculation_inputData_tableSet_tables
} from "./hooks/schema/QueryCalculationResultInputData";
import { QueryCalculationResultOutputData_solutions_solution_useCases_useCases_calculation_resultData_tableSet_tables } from "./hooks/schema/QueryCalculationResultOutputData";

const useStyles = makeStyles(theme => ({
  flexContainer: { display: "flex", flexDirection: "column", height: "100%" },
  tableView: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    overflow: "auto"
  }
}));

type InputDataTablesProps =
  QueryCalculationResultInputData_solutions_solution_useCases_useCases_calculation_inputData_tableSet_tables;

type ResultDataTablesProps =
  QueryCalculationResultOutputData_solutions_solution_useCases_useCases_calculation_resultData_tableSet_tables;

interface CalculationResultTableProps {
  useCaseKey: string;
  useCaseVersion: string;
  calculationId: string;
  name: string;
  columnDefs?: (
    schemaName: string
  ) => RenderableColumnDefinition<IColumnProps, {}>[];
  loadData: (
    variables: QueryCalculationResultInputDataVariables
  ) => Promise<InputDataTablesProps | ResultDataTablesProps | null | undefined>;
  backgroundColor: string;
}
export const CalculationResultTable = ({
  useCaseKey,
  useCaseVersion,
  calculationId,
  name,
  columnDefs,
  loadData,
  backgroundColor
}: CalculationResultTableProps) => {
  const { flexContainer, tableView } = useStyles();

  return (
    <div className={flexContainer}>
      <div key={name} className={tableView}>
        {/* @ts-ignore */}
        <Header name={name} backgroundColor={backgroundColor} />
        <TableSetGrid
          key={name}
          columnDefs={columnDefs}
          name={name}
          useCaseKey={useCaseKey}
          loadData={loadData}
          /* @ts-ignore */
          useCaseVersion={useCaseVersion}
          calculationId={calculationId}
        />
      </div>
    </div>
  );
};

export default CalculationResultTable;
