import { gql, useQuery } from "@apollo/client";
import {
  UseCaseManagementOverview,
  UseCaseManagementOverviewVariables
} from "./schema/UseCaseManagementOverview";

export const queryUseCaseManagementList = gql`
  query UseCaseManagementOverview($solutionKey: Key!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCases(latestVersionOnly: false) {
            detail {
              key
              name
              version
              description
              tags
            }
          }
        }
      }
    }
  }
`;

export const useUseCaseManagementOverview = (solutionKey: string) =>
  useQuery<UseCaseManagementOverview, UseCaseManagementOverviewVariables>(
    queryUseCaseManagementList,
    { variables: { solutionKey } }
  );
