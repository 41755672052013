import { gql } from "@apollo/client";

export const mutationDeleteUseCase = gql`
  mutation DeleteUseCase($solutionKey: Key!, $useCaseKey: Key!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          deleteUseCase(useCaseKey: $useCaseKey)
        }
      }
    }
  }
`;
