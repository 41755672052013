import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Card } from "components/error";

const useStyles = makeStyles(theme => ({
  text: {
    color: "rgba(0, 0, 0, 0.6)",
    padding: theme.spacing(2),
    backgroundColor: "rgba(0, 0, 0, 0.04)"
  }
}));

export function InvalidRouteBase({ children }: { children: React.ReactNode }) {
  const classes = useStyles();
  return (
    <Card
      withActions={false}
      title="We could not find the page you are looking for"
    >
      <div className={classes.text}>{children}</div>
    </Card>
  );
}

export default function InvalidRoute() {
  return (
    <InvalidRouteBase>
      <Typography>
        Check for spelling mistakes in the page address or use another link
      </Typography>
    </InvalidRouteBase>
  );
}
