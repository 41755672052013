import { LinearProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ErrorBox } from "components/error";
import { useSolutionKey } from "features/solution";
import CalculationNotExist from "./CalculationNotExist";
import CalculationResultTable from "./CalculationResultTable";
import { extractCalculationData } from "./calculationResultUtils";
import {
  useCalculationInputData,
  useQueryCalculationSchema
} from "./hooks/calculationResultHooks";

const useStyles = makeStyles(theme => ({
  mainBackgroundColor: {
    backgroundColor: theme.palette.primary.main
  },
  margin: {
    marginBottom: theme.spacing(1)
  }
}));

interface CalculationResultInputTableProps {
  useCaseKey: string;
  useCaseVersion: string;
  calculationId: string;
  tableId: string;
}

const CalculationResultInputTable = ({
  useCaseKey,
  useCaseVersion,
  calculationId,
  tableId: name
}: CalculationResultInputTableProps) => {
  const solutionKey = useSolutionKey();
  const { mainBackgroundColor, margin } = useStyles();
  const { data, loading, error } = useQueryCalculationSchema({
    solutionKey,
    useCaseKey,
    useCaseVersion,
    calculationId
  });

  const { loadInputData } = useCalculationInputData();
  const calculation =
    data?.solutions.solution?.useCases?.useCases?.[0]?.calculation;

  if (loading) return <LinearProgress className={margin} />;
  if (error) return <ErrorBox apolloError={error} />;
  if (!calculation) {
    return <CalculationNotExist />;
  }
  const { inputColumnDefs } = extractCalculationData(calculation);
  return (
    <CalculationResultTable
      useCaseKey={useCaseKey}
      useCaseVersion={useCaseVersion}
      columnDefs={inputColumnDefs}
      name={name}
      calculationId={calculationId}
      loadData={loadInputData}
      backgroundColor={mainBackgroundColor}
    />
  );
};
export default CalculationResultInputTable;
