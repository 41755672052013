import makeStyles from "@mui/styles/makeStyles";

export const actionBarId = "action-bar";

const useStyles = makeStyles(_ => ({
  actionBar: {
    display: "flex",
    flexDirection: "row-reverse"
  }
}));

export default function ActionBar() {
  const classNames = useStyles();
  return <div id={actionBarId} className={classNames.actionBar} />;
}
