import { IconButton, SvgIconTypeMap, Tooltip, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  noBorderRadius: {
    borderRadius: 0
  }
}));

export default function ViewModeToggleButton({
  title,
  testId,
  icon: Icon,
  handleClick
}: {
  title: string;
  testId?: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;

  handleClick: React.MouseEventHandler<HTMLButtonElement>;
}) {
  const classNames = useStyles();
  return (
    <Tooltip title={<Typography>{title}</Typography>}>
      <IconButton
        className={classNames.noBorderRadius}
        color="secondary"
        size="large"
        onClick={handleClick}
      >
        <Icon data-testid={testId} />
      </IconButton>
    </Tooltip>
  );
}
