import { CheckCircle, CloudUpload, Publish } from "@mui/icons-material";
import { CircularProgress, Grid, TextField, Tooltip } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import clsx from "clsx";
import Link from "components/base/Link";
import KeyAndNameInputs from "components/form/KeyAndNameInputs";
import TagsInput from "components/form/TagsInput";
import {
  FormikErrors,
  FormikHandlers,
  FormikTouched,
  FormikValues
} from "formik";
import React, { Dispatch, FC, useEffect } from "react";
import { DropzoneArea } from "react-mui-dropzone";
import { uploadTemporaryObject } from "utils/storage";
import { Action, State } from "./hooks/usePublishFormReducer";
import useStyles from "./hooks/usePublishFormStyles";

function readDataUrl(file?: Blob) {
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    if (!file) return reject();
    const reader = new FileReader();
    reader.onabort = reject;
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });
}

interface PublishFormProps {
  state: State;
  dispatch: Dispatch<Action>;
  updateUseCase: boolean;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: FormikHandlers["handleBlur"];
  isValid?: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: FormikValues;
  version?: string;
  setFieldTouched: (
    value: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  validateForm: (values?: any) => Promise<FormikErrors<FormikValues>>;
}

const PublishForm: FC<PublishFormProps> = ({
  state,
  values: { name, key, calculationRuleAddress },
  dispatch,
  updateUseCase,
  errors,
  touched,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  version,
  validateForm
}) => {
  const classes = useStyles();

  const { loadImage, loadRule, tags, image, autoGeneratedKey, dropzoneKey } =
    state;
  useEffect(() => {
    if (updateUseCase) {
      validateForm();
    }
  }, [updateUseCase, validateForm]);
  const IconComponent = (loading?: boolean) =>
    loading ? (
      <div className={classes.iconComponent}>
        <CircularProgress className={classes.fileUploadProgress} />
        <Publish className={classes.uploadUncompletedIcon} />
      </div>
    ) : (
      <Publish className={classes.uploadUncompletedIcon} />
    );
  const CalculationRuleIconComponent = () => IconComponent(loadRule);
  const UseCaseImageIconComponent = () => IconComponent(loadImage);
  return (
    <form
      onSubmit={() => {
        handleSubmit();
      }}
      className={classes.form}
      noValidate
    >
      <Grid container direction="column">
        <KeyAndNameInputs
          value={state.key}
          name={state.name}
          autoGeneratedKey={autoGeneratedKey}
          dispatch={dispatch}
          errors={errors}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          featureName="web service"
          updateMode={updateUseCase}
          touched={touched}
          handleBlur={handleBlur}
        />
        {version && !updateUseCase ? (
          <Link
            to={`/create-use-case-version/${key}`}
            className={classes.link}
            data-testid="version-exists"
          >
            Publish new version
            <CloudUpload style={{ marginLeft: 8 }} />
          </Link>
        ) : null}
        <Tooltip
          title="The description may be used to describe the feature or to take notes for it"
          placement="top-start"
        >
          <TextField
            id="outlined-description"
            label="Description"
            name="description"
            inputProps={{ maxLength: 1000 }}
            multiline={true}
            minRows={2}
            maxRows={10}
            value={state.description}
            onChange={e => {
              e.persist();
              setFieldTouched("description", true, false);
              const value = e.target.value;
              dispatch({ type: "SET_DESCRIPTION", value });
              handleChange(e);
            }}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              className: classes.label
            }}
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline
              }
            }}
          />
        </Tooltip>
        <Grid container wrap="nowrap" justifyContent="space-between">
          <Tooltip
            title="Calculation-Rule files can be created within the SCS and contain logic that transforms tabular input to tabular output data"
            placement="top-start"
          >
            <div className={clsx(classes.relative, classes.adjoin)}>
              <DropzoneArea
                key={`dropzone file ${dropzoneKey}`}
                showPreviewsInDropzone={true}
                useChipsForPreview
                //@ts-ignore
                Icon={
                  calculationRuleAddress
                    ? CheckCircle
                    : CalculationRuleIconComponent
                }
                onDrop={async files => {
                  const file = files[0];
                  dispatch({ type: "LOAD_RULE", value: true });
                  if (file) {
                    const calculationRuleAddress = await uploadTemporaryObject(
                      file,
                      name
                    );

                    dispatch({
                      type: "DROP_RULE",
                      value: calculationRuleAddress
                    });
                    setFieldValue(
                      "calculationRuleAddress",
                      calculationRuleAddress
                    );
                    dispatch({ type: "LOAD_RULE", value: false });
                  }
                }}
                onDelete={() => {
                  dispatch({ type: "DROP_RULE", value: undefined });
                  setFieldValue("calculationRuleAddress", undefined);
                  dispatch({ type: "LOAD_RULE", value: false });
                }}
                filesLimit={1}
                acceptedFiles={[".dtf", ".hive", ".zip"]}
                dropzoneText={"Drag or drop here or browse calculation rule *"}
                classes={{
                  root: clsx(
                    classes.dropzoneClass,
                    classes.dropzoneHack,
                    calculationRuleAddress
                      ? classes.uploadCompleted
                      : classes.uploadUncompleted,
                    errors.calculationRuleAddress
                      ? classes.dropzoneErrorBorder
                      : classes.dropzoneBorderColor
                  ),
                  icon: calculationRuleAddress
                    ? classes.uploadCompletedIcon
                    : classes.uploadUncompletedIcon,
                  text: classes.text,
                  textContainer: classes.textContainer
                }}
                previewGridClasses={{
                  container: classes.gridContainer
                }}
                previewChipProps={{
                  classes: {
                    root: classes.maxWidth
                  }
                }}
                maxFileSize={250 * 1024 * 1024} // 250 MB
              />
            </div>
          </Tooltip>
          <Tooltip
            title="The icon shown in the calculation rule"
            placement="top-start"
          >
            <div className={clsx(classes.relative, classes.adjoin)}>
              <DropzoneArea
                key={`dropzone icon ${dropzoneKey}`}
                showPreviewsInDropzone={true}
                useChipsForPreview
                //@ts-ignore
                Icon={image ? CheckCircle : UseCaseImageIconComponent}
                onDrop={async files => {
                  const file = files[0];
                  dispatch({ type: "LOAD_ICON", value: true });
                  const value = await readDataUrl(file);
                  dispatch({ type: "DROP_ICON", value });
                }}
                onDelete={() => dispatch({ type: "DROP_ICON", value: null })}
                filesLimit={1}
                acceptedFiles={["image/*"]}
                dropzoneText={"Drag or drop here or browse calculation icon"}
                initialFiles={image ? [image] : []}
                classes={{
                  root: clsx(
                    classes.dropzoneClass,
                    classes.dropzoneBorderColor,
                    !image ? classes.uploadUncompleted : classes.uploadCompleted
                  ),
                  icon: image
                    ? classes.uploadCompletedIcon
                    : classes.uploadUncompletedIcon,
                  text: classes.text,
                  textContainer: classes.textContainer
                }}
                previewGridClasses={{
                  container: classes.gridContainer
                }}
                previewChipProps={{
                  classes: {
                    root: classes.maxWidth
                  }
                }}
              />
            </div>
          </Tooltip>
        </Grid>
        <Tooltip
          title=" A list of tags which help to categorize, identify or bookmark a web service"
          placement="top-start"
        >
          <div>
            <TagsInput tags={tags} dispatch={dispatch} />
          </div>
        </Tooltip>
      </Grid>
    </form>
  );
};

export const PrimaryButton: FC<ButtonProps> = props => {
  const classes = useStyles();
  return (
    <Button
      {...props}
      variant="contained"
      color="primary"
      className={clsx(classes.button, classes.buttonPrimary)}
    />
  );
};

export const SecondaryButton: FC<ButtonProps> = props => {
  const classes = useStyles();
  return (
    <Button
      {...props}
      variant="contained"
      className={clsx(classes.button, classes.buttonSecondary)}
    />
  );
};

export default PublishForm;
