import { gql, useQuery } from "@apollo/client";
import { Rename } from "utils/typescript";
import {
  QueryUseCaseList,
  QueryUseCaseListVariables,
  QueryUseCaseList_solutions_solution_useCases_useCases_detail
} from "./schema/QueryUseCaseList";

export type UseCaseType = Rename<
  QueryUseCaseList_solutions_solution_useCases_useCases_detail,
  "key",
  "useCaseKey"
>;

export const queryUseCaseList = gql`
  query QueryUseCaseList($solutionKey: Key!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCases(latestVersionOnly: true) {
            detail {
              name
              image
              description
              tags
              key
              version
            }
          }
        }
      }
    }
  }
`;

export default function useQueryUseCaseList(solutionKey: string) {
  return useQuery<QueryUseCaseList, QueryUseCaseListVariables>(
    queryUseCaseList,
    {
      variables: { solutionKey }
    }
  );
}
