import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  AvailableGroups,
  AvailableGroupsVariables
} from "./schema/AvailableGroups";
import { Members, MembersVariables } from "./schema/Members";

export const membersList = gql`
  query Members($solutionKey: Key!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        access {
          users {
            firstName
            groups
            lastName
            name
            permissions
          }
        }
      }
    }
  }
`;

export const availableGroupsList = gql`
  query AvailableGroups($solutionKey: Key!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        access {
          availableGroups {
            name
          }
        }
      }
    }
  }
`;

export const useQueryGetMembers = (solutionKey: string) =>
  useLazyQuery<Members, MembersVariables>(membersList, {
    variables: { solutionKey }
  });

export const useQueryGetAvailableGroups = (solutionKey: string) =>
  useQuery<AvailableGroups, AvailableGroupsVariables>(availableGroupsList, {
    variables: { solutionKey }
  });
